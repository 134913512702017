import {
  DashboardIcon,
  ExitIcon,
  HamburgerMenuIcon,
} from "@radix-ui/react-icons";
import { Form, Link, useLocation } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { ModeToggle } from "~/components/mode-toggle";
import { NavMenu } from "./layouts/nav-menu-alt";
import CtrlvLogo from "~/images/ctrlv.png";
import { useState, MouseEvent } from "react";
import { useSession } from "~/contexts/auth";
import { Feature } from "./controls/feature";
import { FeatureFlags } from "~/lib/features";
import { AppRoutes } from "~/lib/routes";
import { dashboardMenuSections, navSections } from "./layouts/nav-items";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isAuthenticated, profile } = useSession();
  const location = useLocation();

  const isDashboardActive = location.pathname === AppRoutes.Dashboard.Index;

  const handleCtrlvRedirect = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open("https://ctrlvsolutions.com", "_blank", "noopener,noreferrer");
  };

  const appVersion = ENV.APP_VERSION;

  const menuSections = isAuthenticated ? dashboardMenuSections : navSections;

  return (
    <div className="sticky top-0 left-0 w-full z-50 bg-white dark:bg-zinc-900/50 shadow-md">
      <Form id="logout-form" method="POST" action="/logout" />
      <header className="flex items-center justify-between px-4 py-2 md:py-4 max-w-7xl mx-auto">
        <div className="flex items-center space-x-4">
          <Link className="flex items-center space-x-3" to="/">
            <img
              src={CtrlvLogo}
              alt="QuickBridge"
              className="h-10 w-10 md:h-12 md:w-12"
            />
            <div className="flex flex-col">
              <Feature name={FeatureFlags.Anonymous}>
                <span className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-white tracking-tight">
                  QuickBridge
                  <sup className="text-[0.4em] text-zinc-50 hover:text-emerald-500 dark:text-gray-900 dark:hover:text-emerald-500 ml-1">
                    v{appVersion}
                  </sup>
                </span>
                <span className="text-xs text-zinc-500 dark:text-gray-400 font-medium">
                  A product of{" "}
                  <a
                    href="https://ctrlvsolutions.com"
                    onClick={handleCtrlvRedirect}
                    className="text-emerald-600 dark:text-emerald-400 hover:underline"
                  >
                    CtrlvSolutions
                  </a>
                </span>
              </Feature>
              <Feature name={FeatureFlags.IsActiveProfile}>
                <span className="text-xl md:text-2xl font-bold text-gray-900 dark:text-white tracking-tight">
                  {profile?.organisation?.name}
                  <sup className="text-[0.4em] text-zinc-500 dark:text-gray-400 ml-1">
                    v{appVersion}
                  </sup>
                </span>
                <span className="text-sm font-medium text-emerald-600 dark:text-emerald-400">
                  QuickBridge
                </span>
              </Feature>
            </div>
          </Link>
        </div>

        <div className="flex items-center space-x-0">
          <Feature name={FeatureFlags.IsActiveProfile}>
            <Link to={AppRoutes.Dashboard.Index} className="block md:hidden">
              <Button
                variant={isDashboardActive ? "default" : "outline"}
                className={`gap-2 ${
                  isDashboardActive
                    ? "bg-emerald-600 text-white hover:bg-emerald-700"
                    : "border-emerald-500 text-emerald-600 hover:bg-emerald-50 dark:border-emerald-400 dark:text-emerald-400 dark:hover:bg-emerald-900"
                }`}
              >
                <span className="hidden md:inline">Dashboard</span>
                <DashboardIcon className="w-4 h-4" />
              </Button>
            </Link>
          </Feature>
          <Feature name={FeatureFlags.Anonymous}>
            <NavMenu
              navSections={menuSections}
              closeMenu={() => setIsMenuOpen(false)}
              className="hidden md:flex"
            />
          </Feature>
        </div>
        <div className="flex items-center space-x-2">
          <div className="hidden md:flex items-center space-x-2">
            {isAuthenticated ? (
              <Button
                form="logout-form"
                type="submit"
                className="w-10 h-10 rounded-full border"
                size="icon"
                variant="ghost"
                title="Logout"
              >
                <span className="sr-only">Logout</span>
                <ExitIcon />
              </Button>
            ) : (
              <>
                <Link to="/login">
                  <Button
                    variant="outline"
                    className="border-emerald-500 text-emerald-600 hover:bg-emerald-50 dark:border-emerald-400 dark:text-emerald-400 dark:hover:bg-emerald-900"
                  >
                    Login
                  </Button>
                </Link>
                <Link to="/signup">
                  <Button className="bg-emerald-600 hover:bg-emerald-700 text-white">
                    Signup
                  </Button>
                </Link>
              </>
            )}
          </div>
          <ModeToggle />
          <Button
            className="md:hidden"
            variant="ghost"
            size="icon"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <HamburgerMenuIcon className="h-6 w-6" />
          </Button>
        </div>
      </header>
      {isMenuOpen && (
        <div className="md:hidden bg-white dark:bg-gray-900 shadow-lg">
          <NavMenu
            navSections={menuSections}
            closeMenu={() => setIsMenuOpen(false)}
          />
          <div className="p-4 space-y-2">
            {isAuthenticated ? (
              <Button
                form="logout-form"
                type="submit"
                className="w-full"
                variant="default"
              >
                Logout
              </Button>
            ) : (
              <>
                <Link to="/login" className="block">
                  <Button
                    variant="outline"
                    className="w-full border-emerald-500 text-emerald-600 hover:bg-emerald-50 dark:border-emerald-400 dark:text-emerald-400 dark:hover:bg-emerald-900"
                  >
                    Login
                  </Button>
                </Link>
                <Link to="/signup" className="block">
                  <Button className="w-full bg-emerald-600 hover:bg-emerald-700 text-white">
                    Signup
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
