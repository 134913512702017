import React, { useState } from "react";
import { Link, useLocation } from "@remix-run/react";
import {
  Menu,
  X,
  ChevronRight,
  LayoutDashboard,
  LucidePuzzle,
} from "lucide-react"; // Import icons
import GridImage from "~/images/grid.png";
import { cn } from "~/lib/utils";
import { Header } from "../header";
import { useSession } from "~/contexts/auth"; // Custom hook to get session
import { Card } from "~/components/ui/card";

interface PageShellProps {
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
}

export function PageShell({
  children,
  className,
  contentClassName,
}: PageShellProps) {
  const { isAuthenticated, user } = useSession(); // Check if the user is authenticated
  const location = useLocation();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const navItems = [
    { name: "Dashboard", path: "/dashboard", icon: LayoutDashboard },
    {
      name: "Integrations",
      path: "/dashboard/integrations",
      icon: LucidePuzzle,
    },
    // { name: 'Connections', path: '/dashboard/connections', icon: Link2 },
  ];

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <Header />
      <div className="min-h-[calc(100vh-100px)] bg-ceramic-50 dark:bg-zinc-900 py-2 sm:py-3 md:py-4 flex relative overflow-hidden">
        <img
          src={GridImage}
          alt=""
          className="fixed inset-0 w-full h-full object-cover object-center opacity-20 dark:opacity-10 transition-opacity duration-300 ease-in-out"
        />
        {isAuthenticated && user?.profile && (
          <>
            {/* Floating Menu (hidden on mobile) */}
            <Card className="fixed top-1/2 left-4 transform -translate-y-1/2 w-56 overflow-hidden transition-all duration-300 hover:shadow-2xl bg-gradient-to-br from-zinc-100 to-ceramic-50 dark:from-gray-800 dark:to-gray-900 border-none rounded-2xl flex flex-col z-10 hidden md:flex">
              <div className="p-4 bg-gradient-to-br from-emerald-400 to-emerald-600 dark:from-emerald-600 dark:to-emerald-800">
                <p className="text-sm font-semibold text-white">
                  {user?.profile?.organisation?.name}
                </p>
              </div>
              <div className="flex flex-col space-y-1 p-4 bg-gray-50 dark:bg-gray-800/50">
                {navItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={cn(
                      "px-3 py-2 text-sm font-medium rounded-md flex items-center transition-colors duration-150",
                      location.pathname === item.path
                        ? "bg-emerald-100 dark:bg-emerald-900/50 text-emerald-700 dark:text-emerald-300"
                        : "text-zinc-700 dark:text-zinc-300 hover:bg-ceramic-100 dark:hover:bg-zinc-800/50"
                    )}
                  >
                    <item.icon className="mr-3" size={18} />
                    {item.name}
                    {location.pathname === item.path && (
                      <ChevronRight className="ml-auto" size={16} />
                    )}
                  </Link>
                ))}
              </div>
            </Card>
            {/* Mobile Navigation */}
            <nav className="md:hidden fixed top-2 left-2 z-10">
              <button
                onClick={toggleMobileNav}
                className="p-2 bg-zinc-100/90 dark:bg-zinc-800/90 backdrop-blur-sm rounded-full shadow-lg text-zinc-700 dark:text-zinc-300"
              >
                {isMobileNavOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
              {isMobileNavOpen && (
                <div className="mt-2 p-4 bg-zinc-100/90 dark:bg-zinc-800/90 backdrop-blur-sm rounded-lg shadow-lg">
                  <p className="text-sm font-semibold text-zinc-500 dark:text-emerald-400 mb-3">
                    {user?.profile?.organisation?.name}
                  </p>
                  {navItems.map((item) => (
                    <Link
                      key={item.name}
                      to={item.path}
                      onClick={toggleMobileNav}
                      className={cn(
                        "px-3 py-2 text-sm font-medium rounded-md flex items-center mb-1 transition-colors duration-150",
                        location.pathname === item.path
                          ? "bg-emerald-100 dark:bg-emerald-900/50 text-emerald-700 dark:text-emerald-300"
                          : "text-zinc-700 dark:text-zinc-300 hover:bg-zinc-200 dark:hover:bg-zinc-700/50"
                      )}
                    >
                      <item.icon className="mr-3" size={18} />
                      {item.name}
                      {location.pathname === item.path && (
                        <ChevronRight className="ml-auto" size={16} />
                      )}
                    </Link>
                  ))}
                </div>
              )}
            </nav>
          </>
        )}
        <div
          className={cn(
            "relative w-full max-w-7xl mx-auto px-2 sm:px-3 md:px-4 py-2 sm:py-3 md:py-4",
            "bg-zinc-100/80 dark:bg-zinc-800/80 backdrop-blur-sm",
            "shadow-xl shadow-zinc-700/10 ring-1 ring-zinc-900/5 rounded-lg",
            "transition-all duration-300 ease-in-out",
            className,
            isAuthenticated ? "md:ml-64" : ""
          )}
        >
          <div className="mx-auto max-w-[150px] sm:max-w-[200px] md:max-w-[250px] mb-2 sm:mb-3 md:mb-4">
            <svg className="w-full h-auto" fill="none" viewBox="0 0 297 24">
              {/* SVG path data */}
            </svg>
          </div>
          <div className={cn("mt-2", contentClassName)}>{children}</div>
        </div>
      </div>
    </>
  );
}
