export const usecases: { title: string; href: string; description: string }[] =
  [
    {
      title: "Accounting Integration",
      href: "/usecases/accounting",
      description:
        "Streamline financial data flow between your accounting software and other business systems.",
    },
    {
      title: "E-invoicing Compliance",
      href: "/usecases/efris",
      description:
        "Automate data transfer to e-invoicing platforms for effortless tax compliance.",
    },
    {
      title: "Industry-Specific",
      href: "/usecases/industry-specific",
      description:
        "Integrate your business systems with industry-specific solutions.",
    },
    {
      title: "Multi-location Sync",
      href: "/usecases/multi-location-sync",
      description:
        "Centralize data from multiple business locations for unified management.",
    },
    {
      title: "Fraud Prevention",
      href: "/usecases/fraud-prevention",
      description:
        "Implement automated checks across systems to detect financial discrepancies.",
    },
    {
      title: "Custom Integrations",
      href: "/usecases/custom-integrations",
      description:
        "Connect your unique business systems and automate specific workflows.",
    },
    {
      title: "Real-time Insights",
      href: "/usecases/real-time-insights",
      description:
        "Enable instant data synchronization for up-to-date business intelligence.",
    },
    {
      title: "Inventory Management",
      href: "/usecases/inventory-management",
      description:
        "Sync inventory data across sales, purchasing, and accounting systems.",
    },
    {
      title: "Data Migrations",
      href: "/usecases/data-migration",
      description:
        "Migrate your data seamlessly, including complex data structures and relationships.",
    },
  ];

export const resources = [
  { title: "Documentation", href: "/docs" },
  { title: "Customer Stories", href: "/stories" },
  { title: "Pricing", href: "/pricing" },
];

export const navSections = [
  {
    title: "Solutions",
    items: usecases,
  },
  {
    title: "Integrations",
    items: [{ title: "Integrations", href: "/integrations" }],
  },
  {
    title: "Resources",
    items: resources,
  },
  {
    title: "Contact Us",
    items: [{ title: "Contact Us", href: "/contact" }],
  },
];

export const dashboardMenuSections = [
  {
    title: "Integrations",
    items: [{ title: "Integrations", href: "/dashboard/integrations" }],
  },
  {
    title: "Connections",
    items: [{ title: "Connections", href: "/dashboard/connections" }],
  },
  {
    title: "Activity",
    items: [{ title: "Activity", href: "/dashboard/activity" }],
  },
];
